<template>
  <div
    class="block w-full mr-2 mt-3 rounded-md py-2 text-white font-semibold bg-gray-400"
  >
    {{ title }} 
  </div>
</template>

<script>

export default {
  name: 'BlockedButton',
  props: {
    title: String,
  }
}
</script>

<style>
</style>
