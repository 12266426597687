<template>
  <router-link
    :to="link"  
    class="block mt-3 rounded-md py-2 text-gray-200 bg-primary-600 border-primary-600 font-semibold
      border-2 hover:text-white hover:bg-primary-400 hover:border-primary-400"
  >
    {{ title }}
  </router-link>
</template>

<script>

export default {
  name: 'SolidButton',
  props: {
    title: String,
    link: String
  }
}
</script>

<style>
</style>
