<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Experts list -->
    <div v-if="!isLoading" class="flex flex-wrap items-stretch mt-9" key="content">
      <ClinicalDataCard
        v-for="clinicalcase in clinicalCases"
        :key="clinicalcase.id"
        :title="clinicalcase.name"
        :isAvailable="isUserHaveAccess"
        :link="casesUrl + clinicalcase.id"
      />
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import ClinicalDataCard from '@/components/clinicalcase/ClinicalDataCard.vue'

export default {
  name: 'ClinicalData',
  components: {
    ClinicalDataCard,
    LoadingScreen,
    FadeTransition
  },
  data: function() {
    return {
      isLoading: true
    }
  },
  computed:{
    ...mapState({
      casesArea: state => state.clinicalData.area
    }),
    ...mapGetters({
      clinicalCases: 'clinicalData/getFilteredDatas',
      isUserHaveAccess: 'isUserHaveAccess'
    }),
    casesUrl: function() {
      return '/' + this.$route.params.therapyarea_url + '/clinical-data/';
    }
  },
  created: function() {
    if (this.cases && (this.casesArea == this.$route.params.therapyarea_url)) {
      this.isLoading = false
    } else {
      this.$store.dispatch('clinicalData/getTherapyAreaData', this.$route.params.therapyarea_url)
        .then(() => this.isLoading = false)
    }
  }
}
</script>
